export default {
  metaInfo: {
    title: '404 page',
  },
  name: '404',
  components: {
  },
  props: {},
  data: () => ({

  }),
  computed: {},
  methods: {},
};
